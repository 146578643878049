<template>
	<z-card
		:image="course.image && course.image.path"
		:title="course.name"
		:description="course.description"
		:url="course._url || course.url"
		:isLocked="isCourseLocked"
		@click="onClick"
	>
		<template v-slot:footer-left>
			<div v-if="!isLoggedIn && course.is_public == '1'" class="d-flex align-items-center w-color-dark-green">
				<svg-open-lock-icon class="open-lock-icon" />
				<span>Bez prihlásenia</span>
			</div>
		</template>
		<template v-slot:footer-right>
			<z-toggle-starred :id="course.id" :starred="course.is_starred_by_active_user" />
		</template>
	</z-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	components: {
		'z-toggle-starred': 		() => import('@/plugins/lib@_component/toggle-starred/z-toggle-starred.vue'),
		'svg-open-lock-icon': 	() => import('./assets/open-lock.svg?inline')
	},

	props: {
		course: { required: true, type: Object }
	},
	methods: {
		onClick(e) {
			if(!this.isLoggedIn){
				localStorage.setItem('LAST_FROM_ROUTE',this.course._url)
			}
			
			e.stopPropagation()
			if(this.isCourseLocked) {
				this.EventBus.$emit('locked-content-accessed')
				return
			}
			window.location = this.course._url || this.course.url 
		}
	},
	computed: {
		...mapGetters('wAuth', [
			'isLoggedIn'
		]),
		isCourseLocked() {
			if(!this.isLoggedIn){
				return !this.course.is_public
			}
			return false //if user is logged in, every course is unlocked 
		}
	}
}
</script>

<style lang="scss" scoped>
.z-card {
	width: initial !important;

	.footer {
		a {
			font-family: 'Boing', sans-serif;
		}
	}

}

.open-lock-icon {
	width: 1.5rem;
	margin-right: 0.5rem;
}

</style>